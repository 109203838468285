import './App.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator, Button, Flex, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import logo from './TeleDaas_Colour.png'


Amplify.configure( {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_INTEGRATION_ID,
    mandatorySignIn: true,
    // Store the user details in the window storage, so they sign out when they close the window
    storage: window.sessionStorage
  }
} );

function App( { signOut, user } )
{
  const streamUrl = process.env.REACT_APP_STREAM_URL;
  const [ searchParams ] = useSearchParams();
  const [ errorText, setErrorText ] = useState( "" );
  const [ loading, setLoading ] = useState( false );

  let queryParams = []
  for ( const entry of searchParams.entries() )
  {
    const [ param, value ] = entry;
    queryParams.push( param + '=' + value );
  }

  function getSimplicityLink()
  {
    setLoading( true )
    axios.get( streamUrl + '?' + queryParams.join( '&' ), {
      headers: { 'Authorization': `${ user.getSignInUserSession().getIdToken().getJwtToken() }` }
    } ).then( ( response ) =>
       {
         setLoading( false )
         if ( response.status === 200 && response.data )
         {
           console.log( response.data )
           window.location.assign( response.data )
         }
       }, ( error ) =>
       {
         setLoading( false )
         console.log( "Error retrieving the streaming link " + error )
         console.log( error.response.data )
         setErrorText( error.response.data )
       }
    );
  }

  return (
     <Flex id="content" direction="column" alignContent="center">
       <View id="signOutButton">
         <Button onClick={ signOut }>Sign Out</Button>
       </View>
       <View width="100%" justifyContent="center">
         <img src={ logo } width="800" alt="Simplicit90Y, delivered by Mirada TeleDaaS" />
       </View>
       <View width="100%">
         <Button
            id="launchButton"
            variation="primary"
            size="large"
            isFullWidth={ true }
            loadingText="Loading"
            onClick={ getSimplicityLink }
            isLoading={ loading }
         >
           Launch Simplicit<sup>90</sup>y
         </Button>
       </View>
       <View id="errorMessage" width="100%">
         { errorText }
       </View>
     </Flex>
  )
}

export default withAuthenticator( App, { hideSignUp: true } );